import PropTypes from 'prop-types';
import { useAuth } from 'lib/hooks/useAuth';

import AuthedUserNavi from './AuthedUserNavi';
import PublicNavi from './PublicNavi';

function Navigation(props) {
  const auth = useAuth().getAuth();


  if (props.type === 'public') {
    return <PublicNavi showLinks={props.showLinks} />;
  }

  return (
    <AuthedUserNavi
      user={auth.user}
      showMenuToggle={props.showMenuToggle}
      showNotificationsEmail={props.showNotificationsEmail}
    />
  );
}

Navigation.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  public: PropTypes.bool,
  showLinks: PropTypes.bool,
  showMenuToggle: PropTypes.bool,
  showNotificationsEmail: PropTypes.bool,
};

Navigation.defaultProps = {
  public: true,
  showLinks: true,
  showMenuToggle: false,
  showNotificationsEmail: true,
};

export default Navigation;

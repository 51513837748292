import React, { useState } from 'react';
import Link from 'components/Link';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAuth } from 'lib/hooks/useAuth';
import ActiveLink from './ActiveLink';
import { useCan } from '../../../lib/hooks/useCan';
import BrandLogo from './BrandLogo';


function AuthedUserNavi(props) {
  const can = useCan();
  const flags = useFlags();
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const [isOpen, _setIsOpen] = useState(false);
  const [isOpenTimeout, setIsOpenTimeout] = useState(0);
  const auth = useAuth().getAuth();


  const setIsOpen = (val) => () => {
    clearTimeout(isOpenTimeout);

    if (val === false) {
      setIsOpenTimeout(setTimeout(() => _setIsOpen(val), 2000));

      return;
    }

    _setIsOpen(val);
  };

  return (
    <>
      <header className='position-fixed' style={{ top: 0, left: 0 }}>
        <nav className='navbar navbar-expand-md'>
          <div className='container-fluid'>
            <div
              role='button'
              tabIndex={0}
              className='dashboard-menu-toggle'
              data-toggle='side-menu'
              aria-label='Side menu'
              onClick={() => setSideMenuOpen((open) => !open)}
            >
              <img alt='side-menu' src='/static/img/icons/small/menu.svg' />
            </div>
            <Link href='/enterprise/dashboard'>
              <a className='navbar-brand'>
                <BrandLogo />
              </a>
            </Link>

            <div className='navigation-bar'>
              <div
                role='button'
                tabIndex={0}
                className={`user ${isOpen ? 'dd-menu-is-open' : ''}`}
                data-trigger={`dd-menu ${isOpen ? 'dd-menu-is-open' : ''}`}
                onClick={setIsOpen(true)}
                onMouseOut={setIsOpen(false)}
                onBlur={setIsOpen(false)}
              >
                <div className='user-message'>
                  Hi, {props.user?.firstName} {props.user?.lastName}
                </div>
                <div
                  className='user-avatar'
                  style={{
                    backgroundImage: 'url("/static/img/placeholder.jpg")',
                  }}
                >
                  <img
                    alt=''
                    data-bg='true'
                    src='/static/img/placeholder.jpg'
                  />
                </div>
                <div
                  className={`dd-menu-dropdown ${isOpen ? 'dd-menu-open' : ''}`}
                  style={{
                    display: isOpen ? 'block' : 'none',
                    opacity: isOpen ? 1 : 0,
                  }}
                >
                  <ul aria-label='User Menu List' className='dd-menu-list'>
                    <li>
                      <Link href='/account/profile'>
                        <a disabled={!!auth?.user?.temporaryPasswordCreatedAt}>Profile</a>
                      </Link>
                    </li>
                    <li>
                      <Link href='/account/settings'>
                        <a disabled={!!auth?.user?.temporaryPasswordCreatedAt}>Settings</a>
                      </Link>
                    </li>
                    <li>
                      <Link href='/account/logout'>
                        <a>Log out</a>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>

      <div className={`side-menu ${sideMenuOpen ? 'side-menu-open' : ''}`}>
        <div className='side-menu-content'>
          <ul className='side-menu-list'>
            <li aria-label='Dashboard' title='dashboard'>
              <ActiveLink
                shallow
                href='/enterprise/dashboard'
                activeClassName='active'
              >
                <a id='nav-dashboard' disabled={!!auth?.user?.temporaryPasswordCreatedAt}>
                  <div className='icon'>
                    <img alt='' src='/static/img/icons/menu/dashboard.svg' />
                    <img alt='dashboard' src='/static/img/icons/menu/dashboardOn.svg' />
                  </div>
                  Dashboard
                </a>
              </ActiveLink>
            </li>
            <li aria-label='Documents' title='documents'>
              <ActiveLink
                shallow
                href='/enterprise/documents'
                activeClassName='active'
                show={(can('VIEW_EJOURNAL') || can('VIEW_EJOURNAL_ALL'))}
              >
                <a id='nav-documents' disabled={!!auth?.user?.temporaryPasswordCreatedAt}>
                  <div className='icon'>
                    <img alt='' src='/static/img/icons/menu/documents.svg' />
                    <img alt='documents' src='/static/img/icons/menu/documentsOn.svg' />
                  </div>
                  Documents
                </a>
              </ActiveLink>
            </li>
            <li aria-label='Esign' title='esign'>
              <ActiveLink
                shallow
                href='/enterprise/esign'
                activeClassName='active'
                show={flags.esignEnabled && can('SEND_NEW_ESIGN')}
              >
                <a id='nav-esign' disabled={!!auth?.user?.temporaryPasswordCreatedAt}>
                  <div className='icon'>
                    <img alt='' style={{ width: 24 }} src='/static/img/icons/menu/esign.svg' />
                    <img alt='esign' style={{ width: 24 }} src='/static/img/icons/menu/esignOn.svg' />
                  </div>
                  Esign
                </a>
              </ActiveLink>
            </li>

            {
              flags.taggingRoom === false ? <></> : (
                <li aria-label='Tagging queue' title='tagging-queue'>
                  <ActiveLink
                    shallow
                    href='/enterprise/tagging-room'
                    activeClassName='active'
                    show={(can('VIEW_TAGGING_ROOM') || can('ASSIGN_TAGGER'))}
                  >
                    <a id='nav-tagging-queue' disabled={!!auth?.user?.temporaryPasswordCreatedAt}>
                      <div className='icon'>
                        <img alt='' src='/static/img/icons/menu/taggingQueue.svg' />
                        <img alt='tagging-queue' src='/static/img/icons/menu/taggingQueueOn.svg' />
                      </div>
                      Tagging Queue
                    </a>
                  </ActiveLink>
                </li>
              )
            }

            <li aria-label='Notary queue' title='notary-queue'>
              <ActiveLink
                href='/enterprise/notary-queue'
                activeClassName='active'
                show={can('VIEW_GENERAL_QUEUE')}
              >
                <a id='nav-notary-queue' href='#' disabled={!!auth?.user?.temporaryPasswordCreatedAt}>
                  <div className='icon'>
                    <img alt='' src='/static/img/icons/menu/queue.svg' />
                    <img alt='notary-queue' src='/static/img/icons/menu/queueOn.svg' />
                  </div>
                  Notary Queue
                </a>
              </ActiveLink>
            </li>
            <li aria-label='Users' title='users'>
              <ActiveLink
                shallow
                href='/enterprise/user-management'
                activeClassName='active'
                show={can('VIEW_USER_MANAGEMENT')}
              >
                <a id='nav-users' href='#' disabled={!!auth?.user?.temporaryPasswordCreatedAt}>
                  <div className='icon'>
                    <img alt='' src='/static/img/icons/menu/usersnew.svg' />
                    <img alt='users' src='/static/img/icons/menu/usersnewOn.svg' />
                  </div>
                  Users
                </a>
              </ActiveLink>
            </li>
            <li aria-label='Organizations' title='organizations'>
              <ActiveLink
                shallow
                href='/internal/organizations'
                activeClassName='active'
                show={can('VIEW_ORGS')}
              >
                <a id='nav-notaries' href='#' disabled={!!auth?.user?.temporaryPasswordCreatedAt}>
                  <div className='icon'>
                    <img alt='' src='/static/img/icons/menu/organizations.svg' />
                    <img alt='organizations' src='/static/img/icons/menu/organizationsOn.svg' />
                  </div>
                  Organizations
                </a>
              </ActiveLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default AuthedUserNavi;

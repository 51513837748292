import LoadingOverlay from 'react-loading-overlay';
import { useSelector } from 'react-redux';
import createSelector from 'selectorator';

const overlaySelector = createSelector(['ui.overlay']);

export default function PageLoadingOverlay() {
  const { show: spinnerActive, message } = useSelector(overlaySelector);

  return (
    <LoadingOverlay
      active={spinnerActive}
      spinner
      text={message}
      styles={{
        overlay: (base) => ({
          ...base,
          position: 'fixed',
        }),
      }}
    />
  );
}

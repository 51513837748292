/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import NProgress from 'nprogress';
import Router from 'next/router';
import { ToastContainer, toast } from 'react-toastify';

import 'styles/components/footer.css';
import 'styles/scss/app.scss';
import 'styles/scss/main.scss';
import 'styles/scss/main-responsive.scss';
import 'react-toggle/style.css';
import '../styles/pages/doc-upload.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import HTTPErrorModal from 'components/Modals/HTTPErrorModal';
import PageLoadingOverlay from 'components/Layout/PageLoadingOverlay';
import { UserPilotProvider } from 'lib/hooks/useUserPilot';
import { LDIdentity } from 'lib/client/launchdarkly';
import ForcePasswordUpdateModal from 'components/Modals/ForcedPasswordUpdateModal';
import { FirebaseProvider } from 'lib/hooks/useFirebase';
import { wrapper } from 'lib/redux/store';
import { ErrorModal, ErrorsProvider } from 'lib/hooks/useErrors';
import { AuthProvider } from 'lib/hooks/useAuth';
import { ApiProvider } from 'lib/hooks/useApi';
import firebase from 'lib/client/firebase';
import { ListenPresence } from 'lib/hooks/useListenPresence';
import { withUseIsIframe } from 'lib/hooks/useIsIframe';

import { LDWrapper } from 'components/LDWrapper';
import Layout from '../components/Layout';


Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());


class _EnlApp extends React.Component {
  render() {
    const { Component, pageProps, isIframe } = this.props;
    const containerPaddingClass = isIframe ? 'dashboard-container-v2' : '';

    return (
      <>
        <ToastContainer
          position={toast.POSITION.BOTTOM_RIGHT}
          autoClose={false}
          newestOnTop={false}
        />
        <ErrorsProvider>
          <ApiProvider>
            <FirebaseProvider client={firebase}>
              <ErrorModal />
              <AuthProvider>
                <LDIdentity>
                  <LDWrapper>
                    <UserPilotProvider>
                      <ListenPresence>
                        <Layout containerClass={containerPaddingClass}>
                          <ForcePasswordUpdateModal />
                          <PageLoadingOverlay />
                          <HTTPErrorModal />

                          <Component {...pageProps} />
                        </Layout>
                      </ListenPresence>
                    </UserPilotProvider>
                  </LDWrapper>
                </LDIdentity>
              </AuthProvider>
            </FirebaseProvider>
          </ApiProvider>
        </ErrorsProvider>
      </>
    );
  }
}

const Wrapped = wrapper.withRedux(withUseIsIframe(_EnlApp));


export default Wrapped;

/* eslint-disable import/no-duplicates */
import firebase from 'firebase/app';
import 'firebase/remote-config';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/database';

import getConfig from 'next/config';

const { publicRuntimeConfig: conf } = getConfig();
const firebaseConfig = JSON.parse(conf.FIREBASE_CONFIG);

// Check that `window` is in scope for the analytics module!
if (typeof window !== 'undefined' && !firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  // To enable analytics. https://firebase.google.com/docs/analytics/get-started
  if ('measurementId' in firebaseConfig) firebase.analytics();
  firebase.remoteConfig().fetchAndActivate();
}

export default firebase;

/* eslint-disable require-yield */
/* eslint-disable no-param-reassign */
import { createSlice, createSelector } from '@reduxjs/toolkit';
import { call, put, takeLatest, select } from 'redux-saga/effects';


/*
 * reducer + plain actions
 */
const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    data: {},
  },
  reducers: {
    loadDashboardSuccess(state, action) {
      state.data = action.payload;
    },
  },
});

const { reducer } = dashboardSlice;

/*
 * export sagas
 */
export const sagas = ({ api }) => {
  function* fetchDashboard() {
    try {
      const auth = yield select((state) => state.auth);
      const data = yield call(api.loadDashboard, auth.token);

      yield put({ type: 'dashboard/loadDashboardSuccess', payload: data });
    } catch (e) {
      yield put({
        type: 'dashboard/loadDashboardFailure',
        message: e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message,
      });
    }
  }

  function* rootDashboardSagas() {
    yield takeLatest('dashboard/loadDashboardRequest', fetchDashboard);
  }

  return rootDashboardSagas;
};

/*
 * export selectors
 */
export const selector = createSelector((state = {}) => {
  return state.auth;
});

/*
 * export reducer
 */
export default reducer;

/* eslint-disable no-param-reassign */
import {
  createSlice, createSelector,
} from '@reduxjs/toolkit';
import _ from 'lodash';


/*
 * reducer + plain actions
 */
const queueSlice = createSlice({
  name: 'queue',
  initialState: {
    sessions: {},
    notaries: {},
  },
  reducers: {
    sessionDisconnected(state, action) {
      const { id } = action.payload;

      const session = state.sessions[id];

      if (session) {
        state.sessions[id].connected = false;
      }

      return state;
    },
    addOrUpdateSession(state, action) {
      const {
        id,
        page,
        participants,
        docs,
        witnesses,
        connected,
      } = action.payload;

      const session = state.sessions[id];

      // if exists then dont touch
      if (session) {
        state.sessions[id] = {
          ...session,
          ...action.payload,
        };

        return state;
      }

      state.sessions[id] = {
        ...action.payload,
        id,
        page,
        participants,
        docs,
        witnesses,
        connected,
      };

      return state;
    },
    assignSessionNotary(state, action) {
      const {
        nsId,
        notaryId,
      } = action.payload;

      const session = state.sessions[nsId];

      if (session) {
        state.sessions[nsId] = {
          ...session,
          request: {
            ...session.request,
            assignedTo: notaryId,
          },
        };
      }

      return state;
    },
    updateSessionRequests(state, action) {
      const { sessions } = action.payload;
      const stateSessionsIds = Object.keys(state.sessions);

      stateSessionsIds.forEach((id) => {
        const sessionUpdate = sessions[id];

        if (sessionUpdate) {
          state.sessions[id] = {
            ...state.sessions[id],
            request: {
              ...state.sessions[id].request,
              ...sessionUpdate,
            },
          };
        }
      });

      return state;
    },
    addOrUpdateNotary(state, action) {
      const {
        id,
        page,
        nsId,
        user,
        notary,
        notaryId,
        connected,
      } = action.payload;

      const i = _.findIndex(state.notaries, { id });

      // if exists then dont touch
      if (i > -1) {
        state.notaries[notaryId] = state.notaries[notaryId] || {};
        state.notaries[notaryId].nsId = nsId;
        state.notaries[notaryId].user = user;
        state.notaries[notaryId].notary = notary;
        state.notaries[notaryId].notaryId = notaryId;
        state.notaries[notaryId].connected = connected;

        if (page) {
          state.notaries[notaryId].page = page;
        }

        return state;
      }

      state.notaries[notaryId] = ({
        id,
        page,
        notaryId,
        connected,
        nsId,
      });

      return state;
    },


    loadNotaries(state, action) {
      state.notaries = action.payload;

      return state;
    },

    notaryDisconnected(state, action) {
      const {
        id,
      } = action.payload;

      const i = _.findIndex(state.notaries, (el) => el.id === id);

      if (i > -1) {
        state.notaries[i].connected = false;
      }

      return state;
    },
  },
});

/*
 * export actions
 */
const { reducer } = queueSlice;


/*
 * export selectors
 */
export const selector = createSelector((state = {}) => state.queue);


/*
 * export reducer
 */
export default reducer;

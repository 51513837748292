import _ from 'lodash';
import React from 'react';
import Head from 'next/head';
import { useFlags } from 'launchdarkly-react-client-sdk';

class LLdWrapper extends React.Component {
  constructor(props, ctx) {
    super(props, ctx);
    this.state = {
      insertLuckyOrange: false,
    };
  }

  async componentDidMount() {
    const { domains = [] } = this.props.flags.luckyOrange || {};
    const host = window.location.host;
    const found = _.find(domains, (el) => _.includes(host, el));

    await this.setState({
      insertLuckyOrange: !!found,
    });
  }

  render() {
    return (
      <>
        <Head>
          {
            this.state.insertLuckyOrange && (
              <script src='/lucky-orange.js' />
            )
          }
        </Head>
        {this.props.children}
      </>
    );
  }
}


export function LDWrapper(props) {
  const flags = useFlags();

  return (
    <LLdWrapper
      flags={flags}
    >
      {props.children}
    </LLdWrapper>
  );
}

/* eslint-disable require-yield */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import getConfig from 'next/config';
// import axios from 'axios';
import * as R from 'ramda';
import createSelector from 'selectorator';
import { call, put, takeLatest } from 'redux-saga/effects';

const { publicRuntimeConfig: conf } = getConfig();

if (conf.API_URL) {
  // axios.defaults.baseURL = conf.API_URL;
  // axios.defaults.withCredentials = true;
}


/*
 * reducer + plain actions
 */
const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: {
    byId: {},
    allIds: [],
  },
  reducers: {
    fetchPermissionsSuccess(state, action) {
      state.byId = R.indexBy(R.prop('id'), action.payload);
      state.allIds = R.keys(state.byId);

      return state;
    },
  },
});

const { reducer } = permissionsSlice;


/*
 * export sagas
 */
export const sagas = ({ api }) => {
  function* updateRolePermissions(action) {
    try {
      const data = yield call(api.updateRolePermissions, action.payload);

      yield put({
        type: 'permissions/updateRolePermissionsSuccess',
        payload: data,
      });
    } catch (e) {
      yield put({
        type: 'permissions/updateRolePermissionsFailure',
        message: e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message,
      });
    }
  }

  function* fetchPermissions() {
    try {
      const data = yield call(api.getPermissions);

      yield put({ type: 'permissions/fetchPermissionsSuccess', payload: data });
    } catch (e) {
      yield put({
        type: 'permissions/fetchPermissionsFailure',
        message: e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message,
      });
    }
  }

  function* rootDocumentsSagas() {
    yield takeLatest('permissions/fetchPermissionsRequest', fetchPermissions);
    yield takeLatest(
      'permissions/updateRolePermissionsRequest',
      updateRolePermissions
    );
  }

  return rootDocumentsSagas;
};

/*
 * export selectors
 */
export const selector = createSelector(['permissions'], R.identity);

/*
 * export reducer
 */
export default reducer;

export function usePermissions() {
  const byId = useSelector(createSelector(['permissions.byId']));
  const allIds = useSelector(createSelector(['permissions.allIds']));

  const permissionsArr = useMemo(() => _.values(byId), [byId]);

  return {
    permissions: permissionsArr,
    byId,
    allIds,
  };
}

import { createSlice } from '@reduxjs/toolkit';
import {
  call, put, takeLatest, all,
} from 'redux-saga/effects';
import getConfig from 'next/config';
// import axios from 'axios';
import * as R from 'ramda';
import _ from 'lodash';
import { getNsParticipants, getParticipantVerificationDetails } from 'utils/notarization';

const { publicRuntimeConfig: conf } = getConfig();

if (conf.API_URL) {
  // axios.defaults.baseURL = conf.API_URL;
}

const normalizeRecordsBy = (records, propKey) => R.pipe(
  R.groupBy(R.prop(propKey)),
  R.toPairs,
  R.map(
    R.juxt([
      R.head,
      R.pipe(R.last, R.head),
    ])
  ),
  R.fromPairs
)(records);

const notaryRoomSlice = createSlice({
  name: 'notaryRoom',
  initialState: {
    participants: {
      byId: {},
    },
    verifications: {
      byId: {},
    },
    ready: false,
  },
  reducers: {
    setTwilioToken(state, action) {
      const { payload: { twilio } } = action;

      state.twilio = twilio;

      return state;
    },
    setParticipants(state, action) {
      const { payload: { participants } } = action;
      const ps = normalizeRecordsBy(participants, 'id');

      state.participants.byId = ps;
      state.participants.allIds = _.keys(ps);

      return state;
    },
    setVerifications(state, action) {
      const { payload: { verifications } } = action;
      const vs = normalizeRecordsBy(verifications, 'notarySessionUserId');

      state.verifications.byId = vs;
      state.verifications.allIds = _.keys(vs);

      return state;
    },
    getVerificationsPending: (state) => ({ ...state, pending: true }),
    getVerificationsSuccess(state, action) {
      const { payload: { verifications } } = action;
      const vs = normalizeRecordsBy(verifications, 'notarySessionUserId');

      state.verifications.byId = vs;
      state.verifications.allIds = _.keys(vs);
      state.pending = false;

      return state;
    },
    getVerificationsError: (state, action) => ({ ...state, pending: false, error: action.payload }),

  },
});


const getData = (func) => (...args) => func(...args).then(R.prop('data'));

export const sagas = () => {
  function* fetchNotaryRoomDetails(action) {
    try {
      const { nsId } = action.payload;
      const participants = yield call(getData((nsId) => getNsParticipants(nsId, { params: { type: ['consumer', 'signer'] } })), nsId);

      yield put({
        type: 'notaryRoom/getVerificationsPending',
        payload: { nsId },
      });
      const toYield = _.map(participants, (p) => call(getData(getParticipantVerificationDetails), nsId, p.id));
      const verifications = yield all([...toYield]);

      yield put({
        type: 'notaryRoom/getVerificationsSuccess',
        payload: { nsId, verifications },
      });
    } catch (e) {
      console.debug(e);
      yield put({ type: 'notaryRoom/getVerificationsError', payload: { message: e.message } });
    }
  }

  function* sagas() {
    yield takeLatest('notaryRoom/getVerifications', fetchNotaryRoomDetails);
  }

  return sagas;
};


const { actions, reducer } = notaryRoomSlice;

export const { setWebviewer } = actions;

export default reducer;

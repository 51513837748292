/* eslint-disable require-yield */
import _ from 'lodash';
/* eslint-disable no-param-reassign */
import { createSlice, createSelector, combineReducers } from '@reduxjs/toolkit';

/*
 * reducer + plain actions
 */
const notaryRoomSlice = createSlice({
  name: 'ui/notaryRoom',
  initialState: {
    toolbar: {
      thirdPartyVideo: { disable: false },
      notaryJournal: { disable: false },
      startSession: { disable: false },
      notifyParticipants: { disable: false },
      shareLink: { disable: false },
      identityVerification: { disable: false },
      transferRoom: { disable: false },
      notaryNotes: { show: false },
    },
    modals: {
      thirdPartyVideo: { show: false },
      notaryJournal: { show: false },
      addSigner: { show: false },
      addWitness: { show: false },
      startSession: { show: false },
      notifyParticipants: { show: false },
      shareLink: { show: false },
      identityVerification: { show: false },
      transferRoom: { show: false },
      notaryNotes: { show: false },
      documentDescription: { show: false },
    },
  },
  reducers: {
    disableToolbarButtons(state) {
      state.toolbar = _.mapValues(state.toolbar, () => ({ disable: true }));
    },
    enableToolbarButtons(state) {
      state.toolbar = _.mapValues(state.toolbar, () => ({ disable: false }));
    },
    disableToolbarButton(state, action) {
      state.toolbar[action.payload] = true;
    },
    enableToolbarButton(state, action) {
      state.toolbar[action.payload] = false;
    },

    showThirdPartyVideoModal(state) {
      state.modals.thirdPartyVideo.show = true;

      return state;
    },
    hideThirdPartyVideoModal(state) {
      state.modals.thirdPartyVideo.show = false;

      return state;
    },

    showNotaryJournalModal(state) {
      state.modals.notaryJournal.show = true;

      return state;
    },
    hideNotaryJournalModal(state) {
      state.modals.notaryJournal.show = false;

      return state;
    },
    showAddSignerModal(state) {
      state.modals.addSigner.show = true;

      return state;
    },
    hideAddSignerModal(state) {
      state.modals.addSigner.show = false;
      state.modals.addWitness.show = false;

      return state;
    },
    showAddWitnessModal(state) {
      state.modals.addWitness.show = true;

      return state;
    },
    hideAddWitnessModal(state) {
      state.modals.addWitness.show = false;

      return state;
    },


    showStartSessionModal(state) {
      state.modals.startSession.show = true;

      return state;
    },
    hideStartSessionModal(state) {
      state.modals.startSession.show = false;

      return state;
    },

    showNotifyParticipantsModal(state) {
      state.modals.notifyParticipants.show = true;

      return state;
    },
    hideNotifyParticipantsModal(state) {
      state.modals.notifyParticipants.show = false;

      return state;
    },

    showShareLinkModal(state) {
      state.modals.shareLink.show = true;

      return state;
    },
    hideShareLinkModal(state) {
      state.modals.shareLink.show = false;

      return state;
    },

    showIdentityVerificationModal(state) {
      state.modals.identityVerification.show = true;

      return state;
    },
    hideIdentityVerificationModal(state) {
      state.modals.identityVerification.show = false;

      return state;
    },
    showTransferRoomModal(state) {
      state.modals.transferRoom.show = true;

      return state;
    },
    hideTransferRoomModal(state) {
      state.modals.transferRoom.show = false;

      return state;
    },
    showNotaryNotesModal(state) {
      state.modals.notaryNotes.show = true;

      return state;
    },
    hideNotaryNotesModal(state) {
      state.modals.notaryNotes.show = false;

      return state;
    },
    // new document notes modal
    showDocumentDescriptionModal(state) {
      state.modals.documentDescription.show = true;

      return state;
    },
    hideDocumentDescriptionModal(state) {
      state.modals.documentDescription.show = false;

      return state;
    },
    // end new document notes modal
  },
});

const overlaySlice = createSlice({
  name: 'ui/overlay',
  initialState: {
    show: false,
    message: null,
    showLogoutWarningModal: false,
    logoutWarningAudio: null,
  },
  reducers: {
    showLogoutWarningModal(state) {
      state.showLogoutWarningModal = true;
      state.logoutWarningAudio = new Audio('/static/sounds/logout-warning.mp3');
      state.logoutWarningAudio.play();
    },
    hideLogoutWarningModal(state) {
      state.showLogoutWarningModal = false;

      if (state.logoutWarningAudio) {
        state.logoutWarningAudio.pause();
      }
    },
    showOverlay(state, action) {
      state.show = true;
      state.message = action.payload;

      return state;
    },
    hideOverlay(state) {
      state.show = false;
      state.message = null;

      return state;
    },
  },
});

const naviSlice = createSlice({
  name: 'ui/navi',
  initialState: {
    show: false,
    message: null,
  },
  reducers: {
    showNavi(state) {
      state.show = true;

      return state;
    },
    hideNavi(state) {
      state.show = false;

      return state;
    },
  },
});

/*
 * export actions
 */
// const { reducer } = uiSlice;
const reducer = combineReducers({
  navi: naviSlice.reducer,
  overlay: overlaySlice.reducer,
  notaryRoom: notaryRoomSlice.reducer,
});

export const selector = createSelector((state = {}) => {
  return state.ui;
});

export default reducer;

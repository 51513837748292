import _ from 'lodash';

/*
 * reducer + plain actions
 */
const loadingReducer = (state = {}, action) => {
  const { type, payload, message } = action;

  if (type === 'loading/clear') {
    return _.mapValues(state, () => false);
  }

  const matches = /(.*)(Request|Success|Failure)/.exec(type);

  // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;

  const val = requestState === 'Request'
    ? true
    : requestState === 'Failure'
      ? message || payload.message
      : false;

  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_TODOS_REQUEST
    //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
    [requestName]: val,
  };
};

export default loadingReducer;

import React from 'react';

import Link from 'components/Link';
import { NavDropdown } from 'react-bootstrap';
import BrandLogo from './BrandLogo';

export default function PublicNavi() {
  return (
    <header>
      <nav className='navbar navbar-expand-md'>
        <div className='container'>
          <Link href='/'>
            <a className='navbar-brand'>
              <BrandLogo />
            </a>
          </Link>

          <button
            aria-controls='navbar-menu'
            aria-expanded='false'
            aria-label='Toggle navigation'
            className='navbar-toggler'
            data-target='#navbar-menu'
            data-toggle='navbar-menu'
            type='button'
          >
            <img alt='' src='/static/img/icons/menu.svg' />
          </button>

          <div className='navbar-menu' id='navbar-menu'>
            <ul className='navbar-nav mr-auto'>
              <li className='nav-item'>
                <Link href='/how-it-works'>
                  <a className='nav-link'>How It Works</a>
                </Link>
              </li>

              <NavDropdown title='Register' id='register-nav-dropdown'>
                <ul className='custom-dropdown-ul text-center'>
                  <li className='dropdown-item'>
                    <Link href='/account/registration'>
                      <a>Register</a>
                    </Link>
                  </li>
                  <li className='dropdown-item'>
                    <Link href='/account/login'>
                      <a>Log In</a>
                    </Link>
                  </li>
                </ul>
              </NavDropdown>

              <li className='nav-item'>
                <Link href='/pricing'>
                  <a className='nav-link' href='#'>
                    Pricing
                  </a>
                </Link>
              </li>

              <li className='nav-item'>
                <Link href='/notarization/setup'>
                  <a className='btn btn-primary' href='#'>
                    Notarize Now
                  </a>
                </Link>
              </li>

              <li className='nav-item'>
                <Link href='/account/login'>
                  <a className='btn btn-primary'>Sign In</a>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

import { createSlice, createSelector } from '@reduxjs/toolkit';
import getConfig from 'next/config';
import axios from 'axios';
import { Cookie } from 'next-cookie';
import _ from 'lodash';
import Router from 'next/router';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as R from 'ramda';
import Promise from 'bluebird';

const { publicRuntimeConfig: conf } = getConfig();

if (conf.API_URL) {
  // axios.defaults.baseURL = conf.API_URL;
  // axios.defaults.withCredentials = true;
}

/*
 * reducer + plain actions
 */
const authSlice = createSlice({
  name: 'auth',
  initialState: {
    resources: [],
    pending: true,
    error: null,
    notary: null,
    user: null,
    ftoken: null,
    token: null,
    tokenExpiresAt: null,
    lastRefreshedAt: new Date(),
  },
  reducers: {
    setPending(state, action) {
      state.pending = action.payload;

      return state;
    },
    getUserSuccess: (state, action) => {
      state.token = action.user.token;
      state.user = action.user;
      state.tokenExpiresAt = action.tokenExpiresAt;
    },
    setUser(state, action) {
      const { user, token, tokenExpiresAt } = action.payload;

      state.user = state.user || user;
      state.notary = user?.notary;
      state.token = token;
      state.tokenExpiresAt = tokenExpiresAt || user.tokenExpiresAt;
      state.lastRefreshedAt = new Date();

      return state;
    },
    getUserResourcesSuccess(state, action) {
      state.resources = action.payload;

      return state;
    },
    updateSelf(state) {
      // this is for the rare case where an admin notary updates their own active status
      state.user.accountActive = !state.user.accountActive;

      return state;
    },
    setError(state, action) {
      state.error = action.payload;

      return state;
    },
    logoutUser() {
      return {
        pending: false,
        ftoken: null,
        user: null,
        token: null,
        tokenExpiresAt: null,
        lastRefreshedAt: new Date(),
      };
    },
  },
});

/*
 * export actions
 */
const { actions, reducer } = authSlice;

export const setError = (error) => (dispatch) => {
  dispatch({
    type: 'auth/setError',
    payload: error,
    message: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
  });
};

export const createAuthCode = (email, password, recaptchaToken) => async (dispatch) => {
  try {
    await axios({
      method: 'POST',
      url: '/api/login',
      data: { email, password, recaptchaToken },
    });
  } catch (error) {
    if (error.response.status === 302) {
      return Router.replace('/account/authenticate');
    }

    return dispatch(setError(error));
  }
};

export const logoutUser = (firebase) => async (dispatch) => {
  try {
    if (firebase.auth().currentUser) {
      await firebase.auth().signOut();
    }

    const { data } = await axios({
      method: 'POST',
      url: '/api/logout',
      params: { type: 'notary' },
    });

    return dispatch({ type: 'auth/logoutUser', payload: data });
  } catch (error) {
    return dispatch({ type: 'auth/logoutUser', payload: error });
  }
};

export const loginUser = (authCode, firebase) => async (dispatch) => {
  try {
    if (firebase.auth().currentUser) {
      await firebase.auth().signOut();
    }

    dispatch({ type: 'auth/setPending', payload: true });
    const {
      data: { token, user, ftoken, tokenExpiresAt },
    } = await axios({
      method: 'POST',
      url: '/api/second-factor',
      data: { code: authCode },
    });

    const cookie = new Cookie();

    if (process.env.SET_COOKIE === '1') {
      cookie.set('token', token, { path: '/', secure: true });
    }

    await firebase.auth().signInWithCustomToken(ftoken);
    dispatch({
      type: 'auth/setUser',
      payload: { user, token, tokenExpiresAt },
    });

    const { resources, user: userData, notaries } = await Promise.props({
      user: axios.get('/api/user', {
        headers: { Authorization: `Bearer ${token}` },
      }).then(R.prop('data')),
      resources: axios.get('/api/user/resources', {
        headers: { Authorization: `Bearer ${token}` },
      }).then(R.path(['data', 'resources'])),
      notaries: axios.get('/api/organization/notaries', {
        headers: token ? { authorization: `Bearer ${token}` } : {},
      }).then(R.path(['data', 'notaries'])),
    });

    dispatch({ type: 'auth/getUserSuccess', user: userData });
    dispatch({ type: 'auth/getUserResourcesSuccess', payload: resources });
    dispatch({ type: 'notaries/add', payload: notaries });

    return dispatch({ type: 'auth/setPending', payload: false });
  } catch (error) {
    console.error('setUserError', error);

    return dispatch(setError(error));
  }
};

export const getUser = (token, params = {}) => async (dispatch, getState) => {
  try {
    if (!params.noRefresh) {
      dispatch({ type: 'auth/getUserRequest' });
      dispatch({ type: 'auth/setPending', payload: true });
    }


    const notariesAllIds = getState().notaries.allIds;
    const notariesById = getState().notaries.byId;
    const { user, notaries } = await Promise.props({
      user: axios.get('/api/user', {
        headers: { Authorization: `Bearer ${token}` },
        spinner: { loading: false },
        handleError: false,
      }).then(R.prop('data')),
      notaries: _.isEmpty(notariesAllIds) ? axios.get('/api/organization/notaries', {
        headers: token ? { authorization: `Bearer ${token}` } : {},
      }).then(R.path(['data', 'notaries'])) : _.values(notariesById),
    });

    dispatch({ type: 'auth/setUser', payload: { user, token, tokenExpiresAt: user.tokenExpiresAt } });
    dispatch({ type: 'notaries/add', payload: notaries });

    return dispatch({ type: 'auth/setPending', payload: false });
  } catch (error) {
    console.error(error);

    if (error?.response?.status === 401 || error?.response?.status === 403) {
      dispatch(logoutUser());
      dispatch({ type: 'auth/setPending', payload: false });

      return Router.replace('/account/logout');
    }

    dispatch({ type: 'auth/setPending', payload: false });

    return dispatch(setError(error));
  }
};

export const { setUser } = { ...actions, loginUser };

const Api = {
  fetchUser: async (token) => {
    try {
      const { data: user } = await axios({
        method: 'GET',
        url: '/api/user',
        headers: token ? { authorization: `Bearer ${token}` } : {},
      });

      return user;
    } catch (err) {
      if (err.response?.status === 401 || err.response?.status === 403) {
        return Router.replace('/account/logout');
      }

      throw err;
    }
  },
  getUserResources: async (token) => {
    const { data: { resources = [] } } = await axios({
      method: 'GET',
      url: '/api/user/resources',
      headers: token ? { authorization: `Bearer ${token}` } : {},
    });

    return resources;
  },
};

/*
 * export sagas
 */
export const sagas = () => {
  function* fetchUser() {
    try {
      const auth = yield select((state) => state.auth);
      const user = yield call(Api.fetchUser, auth.token);

      yield put({ type: 'auth/getUserSuccess', user });
    } catch (e) {
      yield put({
        type: 'auth/getUserFailure',
        message: e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message,
      });
    }
  }


  function* getUserResources() {
    try {
      const auth = yield select((state) => state.auth);
      const userResources = yield call(Api.getUserResources, auth.token);

      yield put({ type: 'auth/getUserResourcesSuccess', payload: userResources });
    } catch (e) {
      yield put({
        type: 'auth/getUserResourcesFailure',
        message: e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message,
      });
    }
  }

  function* rootAuthSagas() {
    yield takeLatest('auth/getUserRequest', fetchUser);
    yield takeLatest('auth/getUserSuccess', getUserResources);
  }

  return rootAuthSagas;
};

/*
 * export selectors
 */

export const selector = createSelector((state = {}) => {
  return state.auth;
});

/*
 * export reducer
 */
export default reducer;

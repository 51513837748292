import { createSlice } from '@reduxjs/toolkit';
import * as R from 'ramda';
import { call, put, takeLatest } from 'redux-saga/effects';
import { useSelector, useDispatch } from 'react-redux';

import _ from 'lodash';
import { useCallback, useMemo } from 'react';


const toById = R.pipe(R.map(R.juxt([R.prop('id'), R.identity])), R.fromPairs);

const requestSlice = createSlice({
  name: 'requests',
  initialState: {
    byId: {},
    allIds: [],
    byStatusIds: {},
    totalsByStatus: {},
    loading: false,
  },
  reducers: {
    setLoading: (state, action) => ({
      ...state,
      loading: !!action.payload,
    }),
    getRequestsSuccess(state, action) {
      state.byId = {
        ...state.byId,
        ...toById(action.payload.data),
      };
      state.allIds = _.keys(state.byId);

      if (action.payload.status) {
        state.byStatusIds[action.payload.status] = state.byStatusIds[action.payload.status] || [];
        state.byStatusIds[action.payload.status] = _.uniq([
          ...state.byStatusIds[action.payload.status],
          ..._.map(action.payload.data, 'id'),
        ]);

        if (action.payload.count) {
          state.totalsByStatus[action.payload.status] = action.payload.count;
        }
      }

      return state;
    },
    getRequestByIdSuccess(state, action) {
      state.byId[action.payload.id] = action.payload;
      state.allIds = R.keys(state.byId);

      return state;
    },
  },
});

const { reducer } = requestSlice;


export const sagas = ({ api }) => {
  function* getRequests({ payload }) {
    try {
      const data = yield call(api.getRequests, payload);

      yield put({
        type: 'requests/getRequestsSuccess',
        payload: { data: data.data, count: data.count, status: payload.status },
      });
    } catch (e) {
      console.log(e);
      yield put({
        type: 'requests/getRequestsError',
        message: e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message,
      });
    }
  }

  function* getRequestById(action) {
    try {
      const data = yield call(api.getRequestById, action.payload.requestId);

      yield put({ type: 'requests/getRequestByIdSuccess', payload: data });
    } catch (e) {
      console.log(e);
      yield put({
        type: 'requests/getRequestByIdError',
        message: e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message,
      });
    }
  }

  function* lockRequest(action) {
    try {
      const data = yield call(api.lockRequest, action.payload.requestId, action.payload.assignTo, action.payload.assignToOrg);

      yield put({ type: 'requests/lockRequestSuccess', payload: data });
    } catch (e) {
      console.log(e);
      yield put({
        type: 'requests/lockRequestError',
        message: e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message,
      });
    }
  }


  function* rootNotariesSagas() {
    yield takeLatest('requests/getRequestByIdRequest', getRequestById);
    yield takeLatest('requests/getRequestsRequest', getRequests);
    yield takeLatest('requests/lockRequestRequest', lockRequest);
    yield takeLatest('requests/lockRequestSuccess', getRequests);
  }

  return rootNotariesSagas;
};


export default reducer;

export const getRequests = (page, limit, params = {}) => async (dispatch, getState, ctx = {}) => {
  const { api } = ctx;

  dispatch(requestSlice.actions.setLoading(true));
  const { data, count } = await api.getRequests({
    page,
    limit,
    ...params,
  });

  dispatch(requestSlice.actions.getRequestsSuccess({ data, count, status: params.status }));
  dispatch(requestSlice.actions.setLoading(false));
};


export function useRequests(statusId) {
  const dispatch = useDispatch();
  const { byId, allIds, byStatusIds, totalsByStatus = {}, loading } = useSelector((state) => state.requests);
  const requests = useMemo(() => {
    if (statusId) {
      return _.values(_.pick(byId, byStatusIds[statusId] || []));
    }

    return _.values(byId);
  }, [byId, byStatusIds, statusId]);

  const handleGetRequests = useCallback((page = 1, limit = 20, params) => {
    dispatch(getRequests(page, limit, params));
  }, [dispatch]);

  return {
    byId,
    byStatusIds,
    requests,
    totalsByStatus,
    allIds,
    loading,
    getRequests: handleGetRequests,
  };
}


// @ts-nocheck
import axios from 'axios';
import getConfig from 'next/config';
import _ from 'lodash';

const { publicRuntimeConfig: conf } = getConfig();

const endPoint = '/api/notarization';

if (conf.API_URL) {
  // axios.defaults.baseURL = conf.API_URL;
  // axios.defaults.withCredentials = true;
}

export const postNotarizationSetup = (data = {}, opts = {}) => axios({
  method: 'POST',
  url: `${endPoint}/start`,
  ...opts,
  data,
});

export const postNotarizationDocument = (id, data, opts = {}) => axios({
  method: 'POST',
  url: `${endPoint}/${id}/document`,
  ...opts,
  data,
});

export const getNsStatus = async (nsId) => axios.get(`${process.env.API_STATUS_URL}${endPoint}/${nsId}/status`);

export const getParticipant = async (nsId, nsUserId) => axios({
  method: 'GET',
  url: `/api/notarization/${nsId}/participants/${nsUserId}`,
  spinner: { loading: false },
  handleError: false,
});

export const sendEvent = async ({ type, data }) => axios({
  method: 'PATCH',
  url: '/api/internal/notarization/event',
  data: { type, data },
  spinner: { loading: false },
  handleError: false,
});

export const submitTermsAgreement = async (nsId, nsUserId, agree) => axios({
  method: 'PATCH',
  url: `/api/notarization/${nsId}/participants/${nsUserId}/terms`,
  data: { accepted: agree },
  spinner: { loading: false },
  handleError: false,
});

export const changeNotarizationType = async (nsId, notarizationType) => axios({
  method: 'PATCH',
  url: `/api/internal/notarization/${nsId}/type`,
  data: { type: notarizationType },
  spinner: { loading: false },
  handleError: false,
});

export const changeDocNotarizationType = async (nsId, docId, notarizationType) => axios({
  method: 'PATCH',
  url: `/api/internal/notarization/${nsId}/doc/${docId}/type`,
  data: { type: notarizationType },
  spinner: { loading: false },
  handleError: false,
});


export const getTwilioToken = (nsId, pinCode = null) => {
  const pinPostFix = _.isEmpty(pinCode) ? '' : `/${pinCode}`;

  // console.log(_.isEmpty(pinCode));
  return axios.get(`/api/notarization/${nsId}/video${pinPostFix}`);
  // if (!nsUserId){
  //   return axios.get(`/api/notarization/${nsId}/video`)
  // }
  // return axios.get(`/api/notarization/${nsId}/participants/${nsUserId}/video`)
};

export const getTwilioTokenForUser = (nsId, nsUserId, token) => axios({
  method: 'GET',
  url: `/api/internal/notarization/${nsId}/participants/${nsUserId}/video`,
  headers: token ? { Authorization: `Bearer ${token}` } : {},
});

export const createNotaryRoomToken = (nsId, payload) => axios.post(`/api/notarization/${nsId}/fbase-token`, {
  ...payload,
});

export const postNotarizationPayment = (nsId, data) => axios.post(`/api/notarization/${nsId}/payments`, data);
export const getNotarizationInvoice = (nsId, hasRequestedCopies, couponCode, userId) => axios({
  method: 'GET',
  url: `/api/notarization/${nsId}/payments`,
  params: { hasRequestedCopies, couponCode, userId },
  spinner: { loading: false },
});

export const getNsParticipants = (nsId, opts = {}) => axios({
  url: `/api/internal/notarization/${nsId}/participants`,
  method: 'GET',
  ...opts,
});

export const getNotarySessions = async (page = 1, limit = 20, params = {}) => {
  const { data } = await axios.get('/api/notary-session', {
    params: {
      ...params,
      page,
      limit,
    },
  });

  return data;
};

export const getNs = (nsId) => axios({
  url: `/api/internal/notarization/${nsId}`,
  method: 'GET',
  handleError: false,
  spinner: { loading: false },
});

export const getNsExists = (nsId) => axios({
  url: `/api/internal/notarization/${nsId}/exists`,
  method: 'GET',
  handleError: false,
  spinner: { loading: false },
});

export const getNsDocuments = (nsId) => axios({
  url: `/api/internal/notarization/${nsId}/documents`,
  method: 'GET',
  handleError: false,
  spinner: { loading: true },
});

export const getDocumentById = (nsId, docId) => axios({
  url: `/api/notarization/${nsId}/document?docId=${docId}`,
  method: 'GET',
  handleError: false,
  spinner: { loading: false },
});

export const getNotarizedDocumentById = (nsId, docId) => axios({
  url: `/api/notarization/${nsId}/document/${docId}/notarized`,
  method: 'GET',
  handleError: false,
  spinner: { loading: false },
});

export const getDocumentUrl = (nsId) => axios({
  url: `/api/notarization/${nsId}/document`,
  method: 'GET',
  handleError: false,
  spinner: { loading: false },
});


export const getDocumentBuffer = (nsId, docId, preview = true) => axios({
  method: 'GET',
  url: `/api/internal/notarization/${nsId}/document?type=buffer${docId ? `&docId=${docId}` : ''}&includeAnnots=${(preview ? '1' : '0')}`,
  responseType: 'arraybuffer',
});

// export const getDocumentBufferDocId = (nsId, docId) => axios({
//   method: 'GET',
//   url: `/api/notarization/${nsId}/document?type=buffer&docId=${docId}`,
//   responseType: 'arraybuffer'
// });

export const getParticipantVerificationDetails = (nsId, userId) => axios({
  url: `/api/internal/notarization/${nsId}/participants/${userId}/verification-results`,
  method: 'GET',
  handleError: false,
  spinner: { loading: false },
});

export const postVADisclaimer = (id, nsuId, data) => axios({ url: `${endPoint}/${id}/participants/${nsuId}/va-disclaimer`, method: 'POST', data });
export const getDocVerifyUrl = (nsId, nsuId) => axios({
  url: `/api/notarization/${nsId}/participants/${nsuId}/document-verification`,
  method: 'GET',
  handleError: false,
  spinner: { loading: false },
});

export const getRetryDocVerifyUrl = (nsId, nsuId) => axios.get(`/api/notarization/${nsId}/participants/${nsuId}/document-verification-retry`);
export const verifyEmail = (nsId, token) => axios({
  url: `/api/notarization/${nsId}/email-verification`,
  method: 'POST',
  handleError: false,
  data: { token },
  spinner: { loading: false },
});


/**
 * Get auth jwt token for doc-retrieval page
 * @param {*} nsId
 * @param {*} docPinCode
 */
export const submitDocVerifyPin = (nsId, docPinCode) => axios({
  method: 'POST',
  url: '/api/archives/verify-doc-pin',
  data: { nsId, docPinCode },
});


/**
 * Get doc-retrieval information. ns info, history, download
 * @param {*} nsId
 * @param {*} docPinCode
 */
export const getDocRetrievalData = (authToken) => axios({
  method: 'GET',
  url: '/api/archives/notarization-info',
  headers: { 'x-auth': `${authToken}` },
});

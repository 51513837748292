import { useEffect } from 'react';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import { useAuth } from 'lib/hooks/useAuth';
import { useLDClient } from 'launchdarkly-react-client-sdk';

const { publicRuntimeConfig: conf } = getConfig();

// This provides LaunchDarkly, but without having any user specified
export const LDProvider = dynamic(async () => {
  const { asyncWithLDProvider } = await import('launchdarkly-react-client-sdk');

  return asyncWithLDProvider({
    clientSideID: conf.LAUNCH_DARKLY_API_KEY,
    user: { key: 'unauthorized' },
    options: { allAttributesPrivate: true },
  });
}, { ssr: false });

// This updates LaunchDarkly to let it know who the currently logged in user is
export const LDIdentityUpdater = ({ children }) => {
  const ldClient = useLDClient();
  const auth = useAuth().getAuth();
  const user = auth?.user;
  const organizationId = user?.organizationId;

  useEffect(() => {
    if (organizationId) {
      ldClient.identify({
        key: organizationId,
      });
    } else {
      ldClient.identify({ key: 'unauthorized' }, null);
    }
  }, [ldClient, organizationId]);

  return <>{children}</>;
};

export const LDIdentity = ({ children }) => <LDProvider><LDIdentityUpdater>{children}</LDIdentityUpdater></LDProvider>;

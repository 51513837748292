// 11:7  error  Imported JSX component _Link must be in PascalCase or SCREAMING_SNAKE_CASE
import LinkDefault from 'next/link';
import useIsIframe from 'lib/hooks/useIsIframe';
import { useRouter } from 'lib/hooks/useRouterIFrame';
import { Children } from 'react';

/**
 * Link is a wrapper around Next's Link component. It will return nextjs's <Link> component
 * if not in iframe. If in iframe it will call postMessage to parent window to navigate.
 *
 * <Link href='/'>
 *  <a>Documents</a>
 * </Link>
 */
export const Link = ({ href, children, ...props }) => {
  const router = useRouter();
  const isIframe = useIsIframe();
  const child = Children.only(children);

  if (isIframe) {
    return React.cloneElement(child, {
      onClick: () => {
        router.push(href);

        if (child.props.onClick) {
          child.props.onClick();
        }
      },
    });
  }

  return (
    <LinkDefault {...{ href, ...props }}>
      {children}
    </LinkDefault>
  );
};

export default Link;

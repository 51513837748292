import React from 'react';

import { Modal, Button } from 'react-bootstrap';
import _ from 'lodash';
import * as R from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import createSelector from 'selectorator';
import { useAuth } from 'lib/hooks/useAuth';

const getErrorMsgs = R.pipe(R.values, R.filter(R.is(String)));

const loadingSelector = createSelector(['loading']);
const errorsSelector = createSelector([loadingSelector], getErrorMsgs);

// Getting an error like this (which basically is saying "you aren't logged in") while you're trying to log in is useless
const isUselessMessage = (msg) => ['invalid token', 'malformed jwt'].includes(msg)
  && ['/account/login', '/account/logout', '/account/authenticate'].includes(window.location.pathname);

function HTTPErrorModal(props) {
  const errors = useSelector(errorsSelector).filter((msg) => !isUselessMessage(msg));
  const auth = useAuth().getAuth();
  const dispatch = useDispatch();

  const authErrorMessage = auth?.error?.response?.data?.message || auth?.error?.message;

  const show = errors.length > 0 || (auth.error && authErrorMessage && !isUselessMessage(authErrorMessage));

  return (
    <Modal
      show={show}
      style={{ zIndex: 3000 }}
      variant='primary'
      onHide={() => dispatch({ type: 'loading/clear' })}
      onShow={props.onShow}
      onEntered={props.onEntered}
      onEnter={props.onEnter}
      className={`f-modal ${show && 'f-modal-open'}`}
    >
      <Modal.Header>Error</Modal.Header>
      <Modal.Body>
        {_.map(errors, (err, i) => {
          return <div key={i}>{err}</div>;
        })}
        {authErrorMessage}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => {
          dispatch({ type: 'loading/clear' });
        }}
        >
          Okay
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default HTTPErrorModal;

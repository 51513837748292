import { withRouter } from 'next/router';
import Link from 'components/Link';
import React, { Children } from 'react';

function ActiveLink({ router, children, activeClassName, show, ...props }) {
  const child = Children.only(children);
  let className = child.props.className || '';

  if (router.pathname.includes(props.href) && activeClassName) {
    className = `${className} ${activeClassName}`.trim();
  }

  if (!show && show !== undefined) {
    return <></>;
  }

  return <Link {...props}>{React.cloneElement(child, { className, href: props.href })}</Link>;
}

export default withRouter(ActiveLink);

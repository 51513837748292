import React, { useState } from 'react';
import { useAuth, useRequireAuth } from 'lib/hooks/useAuth';
import { useApi } from 'lib/hooks/useApi';
import * as Yup from 'yup';
import { Formik, Field, ErrorMessage } from 'formik';
import { Form, Alert } from 'react-bootstrap';
import { useRouter } from 'next/router';


const strongRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*\(\)\-_\+\."'~\\`\[\]:;><,\./|]).{8,}$/;


const schema = Yup.object().shape({
  currentPassword: Yup.string().required('Password is required!'),
  newPassword: Yup.string().required('Password is required!')
    .matches(strongRegex, 'Must contain 8 characters, one uppercase, one lowercase, one special character'),
  confirmPassword: Yup.string().required('Password is required!').when('newPassword', {
    is: (val) => (!!(val && val.length > 0)),
    then: Yup.string().oneOf(
      [Yup.ref('newPassword')],
      'Both passwords need to be the same'
    ),
  }),
});

function UpdatePassword(props) {
  useRequireAuth();
  const { user } = useAuth().getAuth();
  const router = useRouter();
  const api = useApi();
  const [, setValidated] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  async function onSubmit(values, { setStatus, resetForm }) {
    setValidated(true);
    setStatus();

    try {
      setSubmitting(() => true);
      await api.post('/api/user/password/change', { currentPassword: values.currentPassword, newPassword: values.newPassword });
      resetForm();
      setStatus({ success: true, message: 'Your password has been successfully updated!' });


      return router.push('/account/logout');
    } catch (error) {
      setSubmitting(() => false);
      resetForm();

      if (error.response.status === 403) {
        setStatus({ success: false, message: error.response.data.message });
      } else {
        // handle other errors
        setStatus({ success: false, message: error.response.data.message });
      }
    }
  }

  return (
    <>
      {
        !!user.temporaryPasswordCreatedAt && (
          <div className='col-sm-12 pl-0'>
            <p style={{ color: 'red' }}>Please change your password to continue.</p>
          </div>
        )
      }
      <Formik
        initialValues={{
          currentPassword: '',
          newPassword: '',
          confirmPassword: '',
        }}
        validationSchema={schema}
        // eslint-disable-next-line react/jsx-no-bind
        onSubmit={onSubmit}
      >
        {({ handleSubmit, status, setStatus, isValid }) => {
          return (
            <Form
              className='form-horizontal'
              noValidate
              validated={false}
              onSubmit={handleSubmit}
              onChange={() => { setStatus(); }}
            >
              {status && (
                <Alert dismissible variant={status.success ? 'success' : 'danger'} onClose={() => setStatus()}>{status.message}</Alert>

              )}

              <Form.Row className='form-row'>
                <div className='col-lg-6'>
                  <Form.Label>Current Password</Form.Label>
                  <Field
                    type='password'
                    required
                    className='form-control'
                    placeholder='Current Password'
                    name='currentPassword'
                  />
                  <div style={{ color: 'red' }}>
                    <ErrorMessage name='currentPassword' />
                  </div>
                </div>
              </Form.Row>
              <Form.Row className='form-row'>
                <div className='col-lg-6'>
                  <Form.Group>
                    <Form.Label>New Password</Form.Label>
                    <Field type='password' required className='form-control' placeholder='New Password' name='newPassword' />
                    <div style={{ color: 'red' }}>
                      <ErrorMessage name='newPassword' />
                    </div>
                  </Form.Group>
                </div>
                <div className='col-lg-6'>
                  <Form.Group>
                    <Form.Label>Confirm Password</Form.Label>
                    <Field
                      type='password'
                      required
                      className='form-control'
                      placeholder='Confirm Password'
                      name='confirmPassword'
                    />
                    <div style={{ color: 'red' }}>
                      <ErrorMessage name='confirmPassword' />
                    </div>
                  </Form.Group>
                </div>
              </Form.Row>
              <div className='form-row mt-1'>
                <div className='col-lg-12'>
                  <div className='form-group text-right m-0'>
                    <button
                      type='submit'
                      disabled={isSubmitting || !isValid}
                      className={props.afterSubmit ? 'btn btn-primary' : 'btn btn-secondary'}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default UpdatePassword;

/* eslint-disable max-len */
import useIsIframe from 'lib/hooks/useIsIframe';
import { useRouter } from 'lib/hooks/useRouterIFrame';
import React, { useMemo } from 'react';
import { publicRoutes } from 'lib/hooks/useAuth';
import Navi from './Navigation';

export default function Layout(props) {
  const router = useRouter();
  const show = useMemo(() => !publicRoutes.includes(router.pathname), [router.pathname]);
  const isIframe = useIsIframe();

  return (
    <div className={show && !isIframe ? 'page-dashboard' : ''}>
      {show && !isIframe && <Navi />}

      <div id='dashboard' className={`h-100 ${props.containerClass}`}>
        {props.children}
      </div>
    </div>
  );
}

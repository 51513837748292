import { createSlice, createSelector } from '@reduxjs/toolkit';
import * as R from 'ramda';
import { call, put, takeLatest } from 'redux-saga/effects';


const toById = R.pipe(R.map(R.juxt([R.prop('id'), R.identity])), R.fromPairs);

/*
 * reducer + plain actions
 */
const rolesSlice = createSlice({
  name: 'roles',
  initialState: {
    byId: {},
    allIds: [],
  },
  reducers: {
    fetchRolesSuccess(state, action) {
      state.byId = toById(action.payload);
      state.allIds = R.keys(state.byId);

      return state;
    },
  },
});

const { reducer } = rolesSlice;


/*
 * export sagas
 */
export const sagas = ({ api }) => {
  function* fetchRoles() {
    try {
      const data = yield call(api.getRoles);

      yield put({ type: 'roles/fetchRolesSuccess', payload: data });
    } catch (e) {
      yield put({
        type: 'roles/fetchRolesFailure',
        message: e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message,
      });
    }
  }

  function* updateUserRole(action) {
    try {
      const data = yield call(
        api.updateUserRole,
        action.payload.userId,
        action.payload.roleId
      );

      // wait for updated roles, permissions, users list
      yield put({ type: 'roles/fetchRolesRequest' });
      yield put({ type: 'permissions/fetchPermissionsRequest' });
      yield put({ type: 'users/fetchUsersRequest' });
      yield put({ type: 'roles/updateUserRoleSuccess', payload: data });
    } catch (e) {
      yield put({
        type: 'roles/updateUserRoleFailure',
        message: e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message,
      });
    }
  }

  function* rootDocumentsSagas() {
    yield takeLatest('roles/fetchRolesRequest', fetchRoles);
    yield takeLatest('roles/updateUserRoleRequest', updateUserRole);
  }

  return rootDocumentsSagas;
};

/*
 * export selectors
 */
export const selector = createSelector((state = {}) => {
  console.log('state', state);

  return state.auth;
});

/*
 * export reducer
 */
export default reducer;

import { configureStore } from '@reduxjs/toolkit';
import { all, spawn } from 'redux-saga/effects';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { initApi } from 'lib/hooks/useApi';
import { combineReducers } from 'redux';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import { createLogger } from 'redux-logger';
import accountReducer, { sagas as accountSagas } from './slices/account';
import authReducer, { sagas as authSagas } from './slices/auth';
import loadingReducer from './slices/loading';
import uiReducer from './slices/ui';
import dashboardReducer, { sagas as dashboardSagas } from './slices/dashboard';
import permissionsReducer, { sagas as permissionsSagas } from './slices/permissions';
import rolesReducer, { sagas as rolesSagas } from './slices/roles';
import usersReducer, { sagas as usersSagas } from './slices/users';
import notariesReducer, { sagas as notariesSagas } from './slices/notaries';
import requestsReducer, { sagas as requestsSagas } from './slices/requests';
import roomReducer, { sagas as roomSagas } from './slices/room';
import notaryRoomReducer, { sagas as notaryRoomSagas } from './slices/notary-room';
import queueReducer from './slices/queue';
import { reducer as notarySessionsReducer } from './slices/notary-sessions';


const defState = {
  notaryRoom: {
    participants: {
      byId: {},
    },
    verifications: {
      byId: {},
    },
    ready: false,
  },
  queue: {
    sessions: {},
    notaries: {},
  },
  account: {
    verify: {
      msg: '',
      result: false,
    },
  },
  ui: {
    navi: { show: true },
    overlay: { show: false },
    notaryRoom: {
      modals: {
        thirdPartyVideo: { show: false },
        notaryJournal: { show: false },
        startSession: { show: false },
        addSigner: { show: false },
        addWitness: { show: false },
        notifyParticipants: { show: false },
        shareLink: { show: false },
        identityVerification: { show: false },
        transferRoom: { show: false },
        notaryNotes: { show: false },
        documentDescription: { show: false },
      },
    },
  },
  room: {
    connectedUsers: {
      byId: {},
      allIds: [],
    },
    toastNotifications: {
      byUserId: {},
    },
    verifications: {
      byId: {},
      allIds: [],
    },
    participants: {
      selectedSigner: null,
      byId: {},
      allIds: [],
    },
    video: {
      token: null,
      identity: null,
    },
    notarySession: null,
    audit: {},
  },
  requests: {
    byId: {},
    allIds: [],
    byStatusIds: {},
    totalsByStatus: {},
  },
  permissions: {
    byId: {},
    allIds: [],
  },
  loading: {},
  roles: {
    byId: {},
    allIds: [],
  },
  users: {
    byId: {},
    allIds: [],
  },
  notarySessions: {
    byId: {},
    allIds: [],
    byStatusIds: {},
    totalsByStatus: {},
  },
  notaries: {
    byId: {},
    allIds: [],
  },
  auth: {
    user: null,
    token: null,
    resources: [],
    ftoken: null,
  },
};

const initRootSaga = (deps) => {
  function* rootSaga() {
    yield all([
      spawn(accountSagas(deps)),
      spawn(authSagas(deps)),
      spawn(dashboardSagas(deps)),
      spawn(permissionsSagas(deps)),
      spawn(rolesSagas(deps)),
      spawn(usersSagas(deps)),
      spawn(notariesSagas(deps)),
      spawn(roomSagas(deps)),
      spawn(requestsSagas(deps)),
      spawn(notaryRoomSagas(deps)),
    ]);
  }

  return rootSaga;
};


export default function initializeStore(conf) {
  const { initialState = defState } = conf;
  const api = initApi();
  const sagaMiddleware = createSagaMiddleware();

  const middleware = [
    sagaMiddleware,
    thunk.withExtraArgument({ api }),
  ];


  if (process.env.NODE_ENV !== 'production') {
    middleware.push(createLogger({ collapsed: true }));
  }

  const _reducer = combineReducers({
    queue: queueReducer,
    account: accountReducer,
    ui: uiReducer,
    dashboard: dashboardReducer,
    loading: loadingReducer,
    auth: authReducer,
    users: usersReducer,
    roles: rolesReducer,
    permissions: permissionsReducer,
    notaries: notariesReducer,
    requests: requestsReducer,
    notaryRoom: notaryRoomReducer,
    room: roomReducer,
    notarySessions: notarySessionsReducer,
  });


  const store = configureStore({
    // devTools: ,
    reducer: (state, action) => {
      if (action.type === HYDRATE) {
        return {
          ...state,
          ...action.payload,
        };
      }

      return _reducer(state, action);
    },
    preloadedState: initialState,
    middleware,
  });

  store.sagaTask = sagaMiddleware.run(initRootSaga({ api }));

  if (typeof window !== 'undefined') {
    window.store = store;
  }

  return store;
}

export const wrapper = createWrapper(initializeStore, { debug: process.env.NODE_ENV !== 'production' });

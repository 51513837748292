import { createSlice } from '@reduxjs/toolkit';
import createSelector from 'selectorator';
import getConfig from 'next/config';
// import axios from 'axios';
import * as R from 'ramda';
import { call, put, takeLatest } from 'redux-saga/effects';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useMemo } from 'react';

const { publicRuntimeConfig: conf } = getConfig();

if (conf.API_URL) {
  // axios.defaults.baseURL = conf.API_URL;
  // axios.defaults.withCredentials = true;
}


/*
 * reducer + plain actions
 */
const usersSlice = createSlice({
  name: 'users',
  initialState: {
    byId: {},
    allIds: [],
  },
  reducers: {
    fetchUsersSuccess(state, action) {
      state.byId = R.indexBy(R.prop('id'), _.map(action.payload, (user) => {
        return {
          ...user,
          firstName: _.upperFirst(user.firstName),
          lastName: _.upperFirst(user.lastName),
        };
      }));
      state.allIds = R.keys(state.byId);

      return state;
    },
  },
});

const { reducer } = usersSlice;


/*
 * export sagas
 */
export const sagas = ({ api }) => {
  function* fetchUsers() {
    try {
      const data = yield call(api.getUsers);

      yield put({ type: 'users/fetchUsersSuccess', payload: data.users });
    } catch (e) {
      yield put({
        type: 'users/fetchUsersFailure',
        message: e?.response?.data?.message ? e?.response?.data?.message : e?.message,
      });
    }
  }

  function* updateUser(action) {
    try {
      const data = yield call(
        api.updateUser,
        action.payload.userId,
        action.payload.values
      );

      // wait for updated roles, permissions, users list
      yield put({ type: 'users/fetchUsersRequest' });
      yield put({ type: 'roles/updateUserSuccess', payload: data });
    } catch (e) {
      yield put({
        type: 'roles/updateUserFailure',
        message: e?.response?.data?.message ? e?.response?.data?.message : e?.message,
      });
    }
  }

  function* rootDocumentsSagas() {
    yield takeLatest('users/fetchUsersRequest', fetchUsers);
    yield takeLatest('users/updateUserRequest', updateUser);
  }

  return rootDocumentsSagas;
};

/*
 * export selectors
 */
export const selector = ((state = {}) => {
  return state.users;
});

/*
 * export reducer
 */
export default reducer;


export function useUsers() {
  const byId = useSelector(createSelector(['users.byId']));
  const allIds = useSelector(createSelector(['users.allIds']));

  const userArr = useMemo(() => _.values(byId), [byId]);

  return {
    users: userArr,
    byId,
    allIds,
  };
}

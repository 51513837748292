import { createSlice, createSelector } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';


/*
 * reducer + plain actions
 */
const accountSlicer = createSlice({
  name: 'account',
  initialState: {
    verify: {
      msg: '',
      result: false,
    },
  },
  reducers: {
    verifySuccess(state, action) {
      // console.log('action.payload', action.payload);
      state.verify.msg = action.payload;
      state.verify.result = true;

      return state;
    },
    verifyError(state, action) {
      // console.log('action.payload', action.payload);
      state.verify.msg = action.payload;
      state.verify.result = false;

      return state;
    },
  },
});

const { reducer } = accountSlicer;


/*
 * export sagas
 */
export const sagas = ({ api }) => {
  function* verifyEmail(action) {
    try {
      const data = yield call(api.verifyEmail, action.payload);

      yield put({ type: 'account/verifySuccess', payload: data.message });
    } catch (err) {
      // console.log(err);
      yield put({
        type: 'account/verifyError',
        payload: _.get(err, 'response.data.message', err.message),
      });
    }
  }

  function* rootSagas() {
    yield takeLatest('account/verifyEmailRequest', verifyEmail);
  }

  return rootSagas;
};

/*
 * export selectors
 */
export const selector = createSelector((state = {}) => {
  // console.log('state', state);
  return state.verify;
});

/*
 * export reducer
 */
export default reducer;

import { createSlice, combineReducers, createReducer } from '@reduxjs/toolkit';
import getConfig from 'next/config';
import axios from 'axios';
import * as R from 'ramda';
import _ from 'lodash';
import { select, call, put, takeLatest } from 'redux-saga/effects';

const { publicRuntimeConfig: conf } = getConfig();

if (conf.API_URL) {
  // axios.defaults.baseURL = conf.API_URL;
  // axios.defaults.withCredentials = true;
}

const toById = R.pipe(R.map(R.juxt([R.prop('id'), R.identity])), R.fromPairs);

const participantsSlice = createSlice({
  name: 'room/participants',
  initialState: {
    selectedSigner: null,
    byId: {},
    allIds: [],
  },
  reducers: {
    getParticipantsSuccess(state, action) {
      state.byId = toById(action.payload.participants);
      state.allIds = R.keys(state.byId);

      return state;
    },
    getParticipantSuccess(state, action) {
      state.selectedSigner = action.payload;

      return state;
    },
    getParticipantError(state) {
      return state;
    },
  },
});

const connectedUsers = createSlice({
  name: 'room/connectedUsers',
  initialState: {
    byId: {},
    allIds: [],
  },
  reducers: {
    setConnectedUsers(state, action) {
      state.byId = action.payload;
      state.allIds = R.keys(action.payload);

      return state;
    },
    updateConnectedUsers(state, action) {
      state.byId = { ...state.byId, ...action.payload };
      state.allIds = [...state.allIds, R.keys(action.payload)];

      return state;
    },
  },
});

const toastNotifications = createSlice({
  name: 'room/toastNotifications',
  initialState: {
    byUserId: {},
  },
  reducers: {
    clearUserToasts(state, action) {
      state.byUserId = _.omit(state.byUserId, action.payload);

      return state;
    },
    updateUserToasts(state, action) {
      state.byUserId = {
        ...state.byUserId,
        [action.payload.userId]: {
          toastId: action.payload.toastId,
          type: action.payload.type,
        },
      };

      return state;
    },
  },
});

const verificationsSlice = createSlice({
  name: 'room/verifications',
  initialState: {
    byId: {},
    allIds: [],
  },
  reducers: {
    getVerificationDetailsSuccess(state, action) {
      state.byId = toById(action.payload.data);
      state.allIds = R.keys(state.byId);

      return state;
    },
  },
});

const nsSlice = createSlice({
  name: 'room/notary-session',
  initialState: {},
  reducers: {
    getNotarySessionSuccess(state, action) {
      state = action.payload;

      return state;
    },
    startNotarySessionSuccess(state, action) {
      state = action.payload;

      return state;
    },
    stopNotarySessionSuccess(state, action) {
      state = action.payload;

      return state;
    },
    updateNotarizationFee(state, action) {
      state.notarizationFee = action.payload;

      return state;
    },
    updateSessionDescription(state, action) {
      state.sessionDescription = action.payload;

      return state;
    },
    updateAdditionalNotes(state, action) {
      state.additionalNotes = action.payload;

      return state;
    },
  },
});


const auditSlice = createSlice({
  name: 'room/audit',
  initialState: {
    history: [],
    error: '',
  },
  reducers: {
    getHistoryRequestSuccess(state, action) {
      state.history = action.payload;

      return state;
    },
    getHistoryRequestError(state, action) {
      state.error = action.payload;

      return state;
    },
  },
});

const videoSlice = createSlice({
  name: 'room/video',
  initialState: {
    token: null,
    identity: null,
  },
  reducers: {
    getVideoTokenSuccess(state, action) {
      state.token = action.payload.token;
      state.identity = action.payload.identity;

      return state;
    },
    clearVideoToken(state) {
      state.token = state.identity = null;

      return state;
    },
  },
});


const pinModalReducer = createReducer(false, { 'room/ui/setPinModalShown': (state, action) => action.payload });


const { reducer: participantsReducer } = participantsSlice;
const { reducer: verificationsReducer } = verificationsSlice;

const Api = {
  getParticipant: async (requestId, nsId, participantId, token) => {
    const { data } = await axios.get(`/api/request/${requestId}/notary-session/${nsId}/participant/${participantId}`, {
      headers: token ? { authorization: `Bearer ${token}` } : {},
    });

    return data;
  },
  getParticipants: async (requestId, nsId, token) => {
    const { data: { data } } = await axios.get(`/api/request/${requestId}/notary-session/${nsId}/participants`, {
      headers: token ? { authorization: `Bearer ${token}` } : {},
    });

    return data;
  },
  getVerificationDetails: async (requestId, nsUserId, token) => {
    const { data } = await axios.get(`/api/request/${requestId}/participants/${nsUserId}/verification`, {
      headers: token ? { authorization: `Bearer ${token}` } : {},
    });

    return data;
  },
  getVideoToken: async (requestId, nsId, token) => {
    const { data } = await axios.get(`/api/request/${requestId}/notary-session/${nsId}/video`, {
      headers: token ? { authorization: `Bearer ${token}` } : {},
    });

    return data;
  },
  patchVideoToggle: async (requestId, nsId, token, enable) => {
    const { data } = await axios.patch(`/api/request/${requestId}/notary-session/${nsId}/video`, enable, {
      headers: token ? { authorization: `Bearer ${token}` } : {},
    });

    return data;
  },
  getNotarySession: async (requestId, nsId, token) => {
    const { data: { data } } = await axios.get(`/api/request/${requestId}/notary-session/${nsId}`, {
      headers: token ? { authorization: `Bearer ${token}` } : {},
    });

    return data;
  },
  getHistoryRequest: async (requestId, nsId, token) => {
    const data = await axios.get(`/api/request/${requestId}/notary-session/${nsId}/history`, {
      headers: token ? { authorization: `Bearer ${token}` } : {},
    });

    return data;
  },
  stopNotarySession: async (requestId, nsId) => {
    const { data: { data } } = await axios.post(`/api/request/${requestId}/notary-session/${nsId}/stop`, {});

    return data;
  },
  startNotarySession: async (requestId, nsId) => {
    const { data: { data } } = await axios.post(`/api/request/${requestId}/notary-session/${nsId}/start`, {});

    return data;
  },
};


export const sagas = () => {
  function* getParticipants(action) {
    try {
      const auth = yield select((state) => state.auth);
      const data = yield call(Api.getParticipants, action.payload.requestId, action.payload.nsId, auth?.token);

      yield put({
        type: 'room/participants/getParticipantsSuccess',
        payload: {
          participants: data,
          nsId: action.payload.nsId,
          requestId: action.payload.requestId,
        },
      });
    } catch (e) {
      yield put({
        type: 'room/participants/getParticipantsError',
        message: e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message,
      });
    }
  }

  function* getParticipant(action) {
    try {
      const auth = yield select((state) => state.auth);
      const data = yield call(Api.getParticipant, action.payload.requestId, action.payload.nsId, action.payload.participantId, auth?.token);

      yield put({
        type: 'room/participants/getParticipantSuccess',
        payload: data,
      });
    } catch (e) {
      const msg = _.get(e, 'response.data.message', e);

      yield put({
        type: 'room/participants/getParticipantError',
        payload: msg,
      });
    }
  }

  function* getVerificationDetails(action) {
    try {
      const auth = yield select((state) => state.auth);
      const resolved = yield call(() => Promise.all(
        _.map(action.payload.participants, (p) => Api.getVerificationDetails(action.payload.requestId, p.id, auth?.token))
      ));

      yield put({
        type: 'room/verifications/getVerificationDetailsSuccess',
        payload: {
          data: resolved,
        },
      });
    } catch (e) {
      yield put({
        type: 'room/verifications/getVerificationDetailsError',
        message: e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message,
      });
    }
  }

  function* getVideoToken(action) {
    try {
      const auth = yield select((state) => state.auth);

      const { token, identity } = yield call(Api.getVideoToken, action.payload.requestId, action.payload.nsId, auth?.token);

      yield put({
        type: 'room/video/getVideoTokenSuccess',
        payload: {
          token,
          identity,
        },
      });
    } catch (e) {
      yield put({
        type: 'room/video/getVideoTokenError',
        message: e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message,
      });
    }
  }


  function* patchVideoToggle(action) {
    try {
      const auth = yield select((state) => state.auth);
      const { nsId, requestId } = action.payload;

      yield call(Api.patchVideoToggle, requestId, nsId, auth?.token, action.payload.content);
      yield put({
        type: 'room/notary-session/getNotarySessionRequest',
        payload: {
          nsId,
          requestId,
        },
      });
      yield put({
        type: 'room/video/getVideoTokenRequest',
        payload: { nsId, requestId },
      });
    } catch (e) {
      yield put({
        type: 'room/video/patchVideoEnableError',
        message: e.message,
      });
    }
  }

  function* getNotarySession(action) {
    try {
      const auth = yield select((state) => state.auth);

      const ns = yield call(Api.getNotarySession, action.payload.requestId, action.payload.nsId, auth?.token);

      yield put({
        type: 'room/notary-session/getNotarySessionSuccess',
        payload: ns,
      });
    } catch (e) {
      yield put({
        type: 'room/notary-session/getNotarySessionError',
        message: e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message,
      });
    }
  }

  function* getHistoryRequest(action) {
    try {
      const auth = yield select((state) => state.auth);

      const { data } = yield call(Api.getHistoryRequest, action.payload.requestId, action.payload.nsId, auth?.token);

      yield put({
        type: 'room/audit/getHistoryRequestSuccess',
        payload: data,
      });
    } catch (e) {
      yield put({
        type: 'room/audit/getHistoryRequestError',
        message: e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message,
      });
    }
  }

  function* startNotarySession(action) {
    try {
      const ns = yield call(Api.startNotarySession, action.payload.requestId, action.payload.nsId);

      yield put({
        type: 'room/notary-session/startNotarySessionSuccess',
        payload: ns,
      });
      yield put({
        type: 'ui/notaryRoom/hideStartSessionModal',
      });
      yield put({
        type: 'room/video/getVideoTokenRequest',
        payload: { nsId: action.payload.nsId, requestId: action.payload.requestId },
      });
    } catch (e) {
      yield put({
        type: 'room/notary-session/startNotarySessionError',
        message: e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message,
      });
    }
  }

  function* stopNotarySession(action) {
    try {
      const ns = yield call(Api.stopNotarySession, action.payload.requestId, action.payload.nsId);

      yield put({
        type: 'room/notary-session/stopNotarySessionSuccess',
        payload: ns,
      });
      yield put({
        type: 'ui/notaryRoom/hideStartSessionModal',
      });
      yield put({
        type: 'room/video/clearVideoToken',
      });
    } catch (e) {
      yield put({
        type: 'room/notary-session/stopNotarySessionError',
        message: e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message,
      });
    }
  }


  function* rootRoomSagas() {
    yield takeLatest('room/notary-session/getNotarySessionRequest', getNotarySession);
    yield takeLatest('room/notary-session/startNotarySessionRequest', startNotarySession);
    yield takeLatest('room/notary-session/stopNotarySessionRequest', stopNotarySession);
    yield takeLatest('room/audit/getHistoryRequest', getHistoryRequest);
    yield takeLatest('room/participants/getParticipantsRequest', getParticipants);
    yield takeLatest('room/participants/getParticipantRequest', getParticipant);
    yield takeLatest('room/participants/getParticipantsSuccess', getVerificationDetails);
    yield takeLatest('room/video/getVideoTokenRequest', getVideoToken);
    yield takeLatest('room/video/patchVideoToggle', patchVideoToggle);
  }

  return rootRoomSagas;
};


const reducer = combineReducers({
  participants: participantsReducer,
  verifications: verificationsReducer,
  connectedUsers: connectedUsers.reducer,
  toastNotifications: toastNotifications.reducer,
  pinModalShown: pinModalReducer,
  video: videoSlice.reducer,
  notarySession: nsSlice.reducer,
  audit: auditSlice.reducer,
});

export default reducer;

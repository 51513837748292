/* eslint-disable no-case-declarations */
import { Modal, Container, Row, Col } from 'react-bootstrap';
import { useAuth } from 'lib/hooks/useAuth';
import React, { useState, useEffect } from 'react';

import UpdatePassword from 'components/UpdatePassword';
import { useRouter } from 'next/router';


function AssignToModal() {
  const [show, setShow] = useState(false);
  const { getAuth } = useAuth();
  const router = useRouter();
  const { user } = getAuth();

  useEffect(() => {
    if (router.pathname !== '/account/settings' && !!user?.temporaryPasswordCreatedAt) {
      router.replace('/account/settings');
    }
  }, [user?.temporaryPasswordCreatedAt, router.pathname, router]);

  return (

    <Modal
      show={show}
      onHide={() => { }}
      variant='primary'
      size='xl'
    >
      <Modal.Header>
        <Modal.Title style={{ color: '#347083' }}>
        Warning!<br />
        You Must Update Your Password Before You Can Continue
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <UpdatePassword afterSubmit={() => setShow(false)} />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default AssignToModal;

import React, { useEffect } from 'react';
import { Userpilot } from 'userpilot';
import { useEffectOnce } from 'react-use';
import getConfig from 'next/config';
import { useAuth } from './useAuth';

const UserPilotCtx = React.createContext();

const { publicRuntimeConfig: conf } = getConfig();


export function UserPilotProvider({ children }) {
  const { user } = useAuth().getAuth();


  useEffectOnce(() => {
    Userpilot.initialize(conf.USER_PILOT_APP_TOKEN);
    Userpilot.anonymous();
  }, []);


  useEffect(() => {
    if (user?.id) {
      Userpilot.identify(user.id, {
        name: `${user?.firstName} ${user?.lastName}`,
        email: user?.email,
      });
    }
  }, [user?.email, user?.firstName, user?.id, user?.lastName]);


  return (
    <UserPilotCtx.Provider>
      {children}
    </UserPilotCtx.Provider>
  );
}


// TODO: hook

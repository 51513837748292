import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { useCallback } from 'react';


export const initialState = {
  byId: {},
  allIds: [],
  byStatusIds: {},
  totalsByStatus: {},
  total: 0,
  loading: false,
};

const slice = createSlice({
  name: 'notary-sessions',
  initialState,
  reducers: {
    setLoading: (state, action) => ({
      ...state,
      loading: !!action.payload,
    }),
    add: (state, action) => {
      const notarySessions = R.indexBy(R.prop('id'), action.payload.data);

      const updated = R.mergeDeepRight(state.byId, notarySessions);

      const allIds = _.uniq([...state.allIds, ..._.keys(notarySessions)]);

      state.allIds = allIds;
      state.byId = updated;

      if (action.payload.status) {
        if (!state.byStatusIds[action.payload.status] || !state.byStatusIds[action.payload.status].length) {
          state.byStatusIds[action.payload.status] = [];
        }

        state.byStatusIds[action.payload.status] = _.uniq([
          ...state.byStatusIds[action.payload.status],
          ..._.map(action.payload.data, 'id'),
        ]);

        if (action.payload.count) {
          state.totalsByStatus[action.payload.status] = action.payload.count;
        }
      }


      return state;
    },
  },
});


export const getNotarySessions = (page, limit, params = {}) => async (dispatch, getState, ctx = {}) => {
  const { api } = ctx;

  dispatch(slice.actions.setLoading(true));
  const { data, count } = await api.getNotarySessions(page, limit, params);

  dispatch(slice.actions.add({ data, count, status: params.status }));
  dispatch(slice.actions.setLoading(false));
};


export const reducer = slice.reducer;
export const selector = (state) => state.notarySessions;
export default slice;


export function useNotarySessions() {
  const dispatch = useDispatch();
  const { ...fields } = useSelector(selector);


  const handleGetNotarySessions = useCallback((page = 1, limit = 20, params) => {
    dispatch(getNotarySessions(page, limit, params));
  }, [dispatch]);


  return {
    ...fields,
    getNotarySessions: handleGetNotarySessions,
  };
}
